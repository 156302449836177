import * as React from "react";
import { RouteComponentProps } from "react-router";
import AddDashboardPage from "../components/pages/admin/AddDashboardPage";
import AddPaginatedReportPage from "../components/pages/admin/AddPaginatedReportPage";


import AddFinancialYearPage from "../components/pages/admin/AddFinancialYearPage";
import AddGroupPage from "../components/pages/admin/AddGroupPage";
import AddKpiPage from "../components/pages/admin/AddKpiPage";
import AddPredicatePage from "../components/pages/admin/AddPredicatePage";
import AddReportPage from "../components/pages/admin/AddReportPage";
import AddUserPage from "../components/pages/admin/AddUserPage";
import EditDashboardPage from "../components/pages/admin/EditDashboardPage";
import EditPaginatedReportPage from "../components/pages/admin/EditPaginatedReportPage";

import EditGroupPage from "../components/pages/admin/EditGroupPage";
import EditKpiPage from "../components/pages/admin/EditKpiPage";
import EditPredicatePage from "../components/pages/admin/EditPredicatePage";
import EditReportPage from "../components/pages/admin/EditReportPage";
import RoleWizardPage from "../components/pages/admin/RoleWizardPage";
import SearchDashboardsPage from "../components/pages/admin/SearchDashboardsPage";
import SearchPaginatedReportsPage from "../components/pages/admin/SearchPaginatedReportsPage";
import SearchGroupsPage from "../components/pages/admin/SearchGroupsPage";
import SearchKpisPage from "../components/pages/admin/SearchKpisPage";
import SearchPredicatesPage from "../components/pages/admin/SearchPredicatesPage";
import SearchReportsPage from "../components/pages/admin/SearchReportsPage";
import SearchRolesPage from "../components/pages/admin/SearchRolesPage";
import SearchSchedulesPage from "../components/pages/SearchSchedulesPage";
import SearchUsersPage from "../components/pages/admin/SearchUsersPage";
import UserWizardPage from "../components/pages/admin/UserWizardPage";
import KpiTargetsPage from "../components/pages/KpiTargetsPage";
import Callback from "../components/pages/Callback";

import CallbackSamlProdEu from "../components/pages/CallbackSamlProdEu";
/*import CallbackSamlProdNa from "../components/pages/CallbackSamlProdNa";*/
import CallbackSamlProdAu from "../components/pages/CallbackSamlProdAu";
import CallbackSamlProdAsia from "../components/pages/CallbackSamlProdAsia";
import CallbackSamlProdNa from "../components/pages/CallbackSamlProdNa";

import CallbackSamlQaEu from "../components/pages/CallbackSamlQaEu";
import CallbackSamlQaNa from "../components/pages/CallbackSamlQaNa";
import CallbackSamlQaAu from "../components/pages/CallbackSamlQaAu";
import CallbackSamlQaAsia from "../components/pages/CallbackSamlQaAsia";

import DashboardSelectionPage from "../components/pages/DashboardSelectionPage";
import ReportViewPage from "../components/pages/ReportViewPage";
import PaginatedReportViewPage from "../components/pages/PaginatedReportViewPage";
import SplashPage from "../components/pages/SplashPage";
import WelcomePage from "../components/pages/WelcomePage";
import TopNavBar from "../components/templates/TopNavBar";
import { paths } from "../constants";
import ListUserDashboardsPage from "../components/pages/ListUserDashboardsPage";

import ListUserPaginatedReportsPage from "../components/pages/ListUserPaginatedReportsPage";
import ListUserReportsPage from "../components/pages/ListUserReportsPage";
import ListUserunitedReportsPage from "../components/pages/ListUserUnitedReportsPage";

import ScheduleWizardPage from "../components/pages/ScheduleWizardPage";
import EmailReportsPage from "../components/pages/EmailReportsPage";
import CallbackEmailReports from "../components/pages/CallbackEmailReports";



export const routes = [
    {
        exact: true,
        main: (props: RouteComponentProps) => <Callback {...props} />,
        path: paths.callback,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackEmailReports {...props} />,
        path: paths.callbackEmailReports,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlProdEu {...props} />,
        path: paths.ssosamlprodeu,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlQaEu {...props} />,
        path: paths.ssosamlqaeu,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlProdAu {...props} />,
        path: paths.ssosamlprodau,
        topbar: () =><TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlQaAu {...props} />,
        path: paths.ssosamlqaau,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlProdNa {...props} />,
        path: paths.ssosamlprodna,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlQaNa {...props} />,
        path: paths.ssosamlqana,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlProdAsia {...props} />,
        path: paths.ssosamlprodas,
        topbar: () => <TopNavBar />
    },

    {
        exact: true,
        main: (props: RouteComponentProps) => <CallbackSamlQaAsia {...props} />,
        path: paths.ssosamlqaas,
        topbar: () => <TopNavBar />
    },


    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSaml {...props} />,
    //    path: paths.ssosamlqa,
    //    topbar: () => <TopNavBar />
    //},
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlNA {...props} />,
    //    path: paths.ssosamlqana,
    //    topbar: () => <TopNavBar />
    //},
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlProdAu {...props} />,
    //    path: paths.ssosamlprodau,
    //    topbar: () => <TopNavBar />
    //},
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlProdNA {...props} />,
    //    path: paths.ssosamlprodna,
    //    topbar: () => <TopNavBar />
    //},
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlProdNA {...props} />,
    //    path: paths.ssosamldevau,
    //    topbar: () => <TopNavBar />
    //},


    {
        exact: true,
        main: (props: RouteComponentProps) => <SplashPage {...props} />,
        path: paths.root,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <SplashPage {...props} />,    
        path: `${paths.sharedDashboard}:uid`,
        topbar: () => <TopNavBar />
    },
    
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlProdAsia {...props} />,
    //    path: paths.ssosamlqaasia,
    //    topbar: () => <TopNavBar />
    //},
    //{
    //    exact: true,
    //    main: (props: RouteComponentProps) => <CallbackSamlProdAsia {...props} />,
    //    path: paths.ssosamlprodasia,
    //    topbar: () => <TopNavBar />
    //},


    {
        exact: false,
        main: (props: RouteComponentProps) => <EmailReportsPage {...props} />,        
        path: `${paths.emailReports}:jobId/:scheduleId`,
        topbar: () => <TopNavBar />
    },
];

export const secureRoutes = [
    {
        exact: true,
        main: (props: RouteComponentProps) => <DashboardSelectionPage {...props} />,
        path: `${paths.pbiDashboards}:id`,        
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <ListUserDashboardsPage />,
        path: `${paths.listUserDashboards}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <ListUserPaginatedReportsPage />,
        path: `${paths.listUserPaginatedReports}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <ListUserReportsPage />,
        path: `${paths.listUserReports}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <ListUserunitedReportsPage />,
        path: `${paths.listUserUnitedReports}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <ReportViewPage {...props} />,
        path: `${paths.sqlReports}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <PaginatedReportViewPage {...props} />,
        path: `${paths.pbiPaginatedReports}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <WelcomePage />,
        path: paths.home,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <KpiTargetsPage {...props} />,
        path: paths.kpitargets,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <ScheduleWizardPage {...props} />,
        path: `${paths.editSchedule}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <ScheduleWizardPage {...props} />,
        path: `${paths.addScheduleWizard}`,
        topbar: () => <TopNavBar />
    }
     


];
export const secureAdminRoutes = [
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddDashboardPage {...props} />,
        path: `${paths.addDashboard}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddPaginatedReportPage {...props} />,
        path: `${paths.addPaginatedReport}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddReportPage {...props} />,
        path: `${paths.addReport}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <RoleWizardPage {...props} />,
        path: `${paths.addRoleWizard}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddUserPage {...props} />,
        path: `${paths.quickAddUser}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <UserWizardPage {...props} />,
        path: `${paths.addUser}`,
        topbar: () => <TopNavBar />
    },
  
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddGroupPage {...props} />,
        path: `${paths.addGroup}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddKpiPage {...props} />,
        path: `${paths.addKpi}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddFinancialYearPage {...props} />,
        path: `${paths.addFinancialYear}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <AddPredicatePage {...props} />,
        path: `${paths.addPredicate}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchDashboardsPage />,
        path: `${paths.searchDashboards}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchPaginatedReportsPage />,
        path: `${paths.searchPaginatedReports}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchReportsPage />,
        path: `${paths.searchReports}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchRolesPage />,
        path: `${paths.searchRoles}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchSchedulesPage />,
        path: `${paths.searchSchedules}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchUsersPage />,
        path: `${paths.searchUsers}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchGroupsPage />,
        path: `${paths.searchGroups}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchKpisPage />,
        path: `${paths.searchKpis}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: () => <SearchPredicatesPage />,
        path: `${paths.searchPredicates}`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditDashboardPage {...props} />,
        path: `${paths.editDashboard}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditPaginatedReportPage {...props} />,
        path: `${paths.editPaginatedReport}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditReportPage {...props} />,
        path: `${paths.editReport}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <RoleWizardPage {...props} />,
        path: `${paths.editRole}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <UserWizardPage {...props} />,
        path: `${paths.editUser}:id`,
        topbar: () => <TopNavBar />
    },
   
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditGroupPage {...props} />,
        path: `${paths.editGroup}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditPredicatePage {...props} />,
        path: `${paths.editPredicate}:id`,
        topbar: () => <TopNavBar />
    },
    {
        exact: true,
        main: (props: RouteComponentProps) => <EditKpiPage {...props} />,
        path: `${paths.editKpi}:id`,
        topbar: () => <TopNavBar />
    }
];
