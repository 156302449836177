export const AUTH_CONFIG = {
    audience: "https://app.ctmsmartdata.com/api/v3",
    clientId: "3uhTDHrSZO52KtPEc3WETgnshWtjlE3O",
    domain: "ctmsmartdata.au.auth0.com",
    scope: "openid email profile access:ctmsmartdata",
    //samlConnection: "portal-qa-idp",
    //samlConnectionNA: "portal-na-stage-idp",
    //samlConnectionProdAu: "portal-prod-idp",
    //samlConnectionProdNA: "portal-na-prod-idp",
    //samlConnectionDevAU: "portal-au-dev-idp",
    //samlConnectionProdAsia: "portal-as-prod-idp",
    //samlConnectionApproval: "ctm-portal-au-qa"



    //Europe
    samlConnectionProdEu: "portal-eu-datahub-prod-idp",
    samlConnectionQaEu: "portal-eu-datahub-qa-idp",

    //Australia
    samlConnectionProdAu: "portal-au-datahub-prod-idp",
    samlConnectionQaAu: "portal-au-datahub-qa-idp",

    //North America
    samlConnectionProdNa: "portal-na-datahub-prod-idp",
    samlConnectionQaNa: "portal-na-datahub-qa-idp",

    //Asia    
    samlConnectionProdAsia: "portal-as-datahub-prod-idp",
    samlConnectionQaAsia: "portal-as-datahub-qa-idp"

};
